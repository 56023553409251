<template>
  <!-- Advertisement Media Content -->
  <div
    class="relative flex flex-col" style="min-height: 60px"
    :style="advertisement.publisher_platform?.includes('landing page') ? 'height: 550px;' : ''"
    :class="[
      { 'h-96 overflow-y-scroll': advertisement.publisher_platform?.includes('landing page') },
      { 'small-width  mx-auto bg-background-hover': shrinkMedia },
      { 'relative': ['dco', 'dpa'].includes(advertisement.type) }
    ]"
  >
    <!-- Loading indicator -->
    <transition>
      <div v-if="loadingMedia && !showError" class="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center z-10">
        <BaseLoadingLogo />
      </div>
    </transition>

    <!-- Dynamic Indicator -->
    <transition>
      <div v-if="(['dco', 'dpa'].includes(advertisement.type)) && showDynamicIndicator && !loadingMedia"
      class="absolute top-3 left-4 flex items-center gap-1 w-max bg-black bg-opacity-30 z-10 py-1 px-1.5 rounded cursor-none pointer-events-none">
        <DynamicFormatIcon :width="15" :height="15" stroke="#fff"/>
        <BaseText type="label" size="xs" class="text-white">
          Dynamic Ad ({{ carouselIndex + 1 }}/{{ advertisement.cards.length }})
        </BaseText>
      </div>
    </transition>

    <img
      v-if="advertisement.type === 'image' && !showError"
      ref="media"
      alt="Image"
      :src="advertisement.image"
      class="w-full transition-opacity duration-100"
      :class="[
        advertisement.publisher_platform?.includes('landing page') ? '' : 'object-contain',
        { 'rounded-lg': rounded },
        loadingMedia ? 'opacity-0' : 'opacity-100'
      ]"
      @load="loadingMedia = false, $emit('loaded-content')"
      @error="showError = true"
    >

    <!-- Image Error -->
    <div v-else-if="showError"
    class="flex flex-col items-center justify-center h-full w-full" :class="{'rounded-lg': rounded}"
    style="min-height: 250px">
      <i class="fas fa-unlink text-2xl mb-2" />
      Broken Media
    </div>

    <!-- Video Player -->
    <BaseAdvertisementVideoPlayer 
      v-else-if="advertisement.type === 'video'"
      ref="videoPlayer"
      :advertisement="advertisement"
      rounded
      class="transition-opacity duration-100"
      :class="loadingMedia ? 'opacity-0' : 'opacity-100'"
      @loaded-thumbnail="loadingMedia = false, $emit('loaded-content')"
      @loaded-content="loadingMedia && (loadingMedia = false), $emit('loaded-content')"
      @error="showError = true"
    />

    <!-- Carousel -->
    <BaseCarousel
      v-else
      ref="carousel"
      :identifier="advertisement.id"
      :items="advertisement.cards"
      :carousel-index.sync="carouselIndex"
      :keep-height-constant="!inAdDetailsDrawer"
      rounded
      class="transition-opacity duration-100"
      :class="loadingMedia ? 'opacity-0' : 'opacity-100'"
      @loaded-content="loadingMedia = false, $emit('loaded-content')"
      @mouseenter.native="showDynamicIndicator = true"
      @mouseleave.native="showDynamicIndicator = false"
      @error="showError = true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import DynamicFormatIcon from '../globals/Icons/FilterIcons/DynamicFormatIcon.vue'

export default {
  name: 'AdvertisementMediaContent',
  components: {
    DynamicFormatIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    shrinkMedia: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    inAdDetailsDrawer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showError: false,
      carouselIndex: 0,
      showDynamicIndicator: false,
      loadingMedia: true,
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
  },
  watch: {
    carouselIndex () {
      this.$emit('update:carousel-index', this.carouselIndex)
      this.$nextTick(() => {
        this.resize()
      })
    },
  },
  mounted () {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      this.safariBrowser = true
      this.showThumbnail = false
    }
  },
  methods: {
    resize () {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    playVideoAtTimestamp (time) {
      // This func is called directly on the component instance from a parent
      if (this.advertisement?.type === 'video' && this.advertisement.video) {
        const videoPlayer = this.$refs.videoPlayer
        videoPlayer.playVideoAtTimestamp(time)
      } else if (['carousel', 'dco'].includes(this.advertisement?.type) && this.advertisement?.cards?.[this.carouselIndex]?.video) {
        const carousel = this.$refs.carousel
        carousel.playVideoAtTimestamp(time)
      }
    },
    setCarouselIndex (index) {
      // This func is called directly on the component instance from a parent
      const carousel = this.$refs.carousel
      if (carousel) {
        carousel.setCarouselIndex(index)
      }
    }
  }
}
</script>

<style scoped>
.small-width {
  max-width: 200px;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
